import { render, staticRenderFns } from "./createModule.vue?vue&type=template&id=bcc87e20&scoped=true&"
import script from "./createModule.vue?vue&type=script&lang=js&"
export * from "./createModule.vue?vue&type=script&lang=js&"
import style0 from "./createModule.vue?vue&type=style&index=0&lang=less&"
import style1 from "./createModule.vue?vue&type=style&index=1&id=bcc87e20&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcc87e20",
  null
  
)

export default component.exports