<!--
 * @Author: your name
 * @Date: 2020-11-24 14:55:24
 * @LastEditTime: 2020-11-24 15:47:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\createInverter.vue
-->
<template>
    <div class="createModule box-create">
        <div class="box-title">{{moduleID ? $t('user.PV.management.modify-PV-module'): $t('PV.modules.new')}}</div>
        <el-form ref="form" :model="form" label-width="auto" label-position='right'>
            <el-row :gutter="24">
                <el-col :span="11">
                    <el-form-item :label="$t('PV.manufacturer')" prop="manufacturerID" :rules="[{ required: true, message: $t('remind.not-null') , trigger: 'change'}]">
                        <el-select v-model="form.manufacturerID" filterable clearable>
                            <el-option
                                    v-for="item in manufacturerList"
                                    :key="item.manufacturerID"
                                    :label="item.name"
                                    :value="item.manufacturerID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <!-- <el-tooltip class="item" effect="dark" :content="$t('PV.addManufacturer')" placement="right"> -->
                    <!-- <el-button>上右</el-button> -->
                    <i class="el-icon-circle-plus-outline outIcon" :title="$t('PV.addManufacturer')" @click="addManufacturer"></i>
                <!-- </el-tooltip> -->
            </el-row>
            <el-row :gutter="24">
                <el-col :span="11">
                    <el-form-item :label="$t('PV.module.type')" prop="name" :rules="[{ required: true, message: $t('remind.not-null') , trigger: 'change'},
                    {max: 100, message: $t('system.max.length')+100, trigger: 'change'}]">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-divider content-position="center">{{$t('new.PV.management.electric-properties')}}</el-divider>
                    <el-form-item :label="$t('PV.rated.power')" prop="pModMPP" :rules="[{ required: true, message: $t('remind.not-null')}
                    ]">
                        <el-input v-model="form.pModMPP"  v-number type="number">
                            <template slot="append">Wp</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.performance-tolerance')" prop="tolerancePModMPP">
                        <el-input v-model="form.tolerancePModMPP" type="number" v-number>
                            <template slot="append">%</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.MPP-voltage')" prop="uModMPP" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.uModMPP" type="number" v-number>
                            <template slot="append">V</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.MPP-current')" prop="iModMPP" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.iModMPP" type="number" v-number>
                            <template slot="append">A</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.open-voltage')" prop="uMod0" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.uMod0" type="number" v-number @blur="calcTempModU0">
                            <template slot="append">V</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.short-voltage')" prop="iModSC" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.iModSC" type="number" v-number @blur="calcTempModISC">
                            <template slot="append">A</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.per-system-voltage')" prop="uModMax" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.uModMax" type="number" v-number >
                            <template slot="append">V</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.PV-module-effi.')" prop="etaMod">
                        <el-input v-model="form.etaMod" type="number" v-number>
                            <template slot="append">%</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.grounding')" prop="isGeneratorGroundRequired" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-select v-model="form.isGeneratorGroundRequired">
                            <el-option :label="$t('new.PV.management.ungrounded')" :value= 2></el-option>
                            <el-option :label="$t('new.PV.management.positive-ground')" :value= 1></el-option>
                            <el-option :label="$t('new.PV.management.negative-ground')" :value= -1></el-option>
                        </el-select>
                    </el-form-item>
                    <el-divider content-position="center">{{$t('new.PV.management.mechanical-properties')}}</el-divider>
                    <el-form-item :label="$t('new.PV.management.number-cells-in-PV')" prop="cellsCount">
                        <el-input v-model="form.cellsCount" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.weight')" prop="moduleWeight" >
                        <el-input v-model="form.moduleWeight" type="number" v-number>
                            <template slot="append">Kg</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.length')" prop="moduleLength" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.moduleLength" type="number" v-number>
                            <template slot="append" >mm</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.width')" prop="moduleWidth" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.moduleWidth" type="number" v-number>
                            <template slot="append">mm</template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item :label="$t('PV.wafer.process')" prop="materialID" :rules="[{ required: true, message: $t('remind.not-null')}]">
                            <el-select v-model="form.materialID" filterable clearable>
                                <el-option
                                        v-for="item in materialList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                    </el-form-item>
                    <el-divider content-position="center">{{$t('new.PV.management.temperature-coefficients')}}</el-divider>
                    <el-form-item :label="$t('new.PV.management.MPP-voltage')" prop="tempModUMPPrel">
                        <el-input v-model="form.tempModUMPPrel" class="inline" type="number"  v-number @blur="calcMPPvol">
                            <template slot="append">%/°C</template>
                        </el-input>
                        <el-input v-model="form.tempModIMPP" class="inline" type="number" v-number @blur="calcMPPvol('total')">
                            <template slot="append" >mV/°C</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.open-voltage')" prop="tempModU0rel" :rules="[{ required: true, message: $t('remind.not-null')},{validator:test_tempModU0rel,trigger:'blur'}]">
                        <el-input v-model="form.tempModU0rel" class="inline" type="number" v-number @blur="calcTempModU0">
                            <template slot="append" >%/°C</template>
                        </el-input>
                        <el-input v-model="form.tempModU0" class="inline" type="number"  v-number @blur="calcTempModU0('total')" >
                            <template slot="append">mV/°C</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.short-voltage')" prop="tempModISCrel">
                        <el-input v-model="form.tempModISCrel" class="inline"  type="number" v-number @blur="calcTempModISC">
                            <template slot="append">%/°C</template>
                        </el-input>
                        <el-input v-model="form.tempModISC" class="inline" type="number" v-number @blur="calcTempModISC('total')">
                            <template slot="append">mA/°C</template>
                        </el-input>
                    </el-form-item>
                    <el-divider content-position="center">{{$t('new.PV.management.degradation-aging')}}</el-divider>
                    <el-form-item :label="$t('new.PV.management.open-circuit-tolerance')">
                        <el-input v-model="form.toleranceModU0" type="number" v-number>
                            <template slot="append">%</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.MPP-voltage-tolerance')">
                        <el-input v-model="form.toleranceModUMPP" type="number" v-number>
                            <template slot="append">%</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.MPP-current-tolerance')">
                        <el-input v-model="form.toleranceModIMPP" type="number" v-number>
                            <template slot="append">%</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('new.PV.management.short-circuit-tolerance')">
                        <el-input v-model="form.toleranceModISC" type="number" v-number>
                            <template slot="append">%</template>
                        </el-input>
                    </el-form-item>
                    <el-divider content-position="center">{{$t('new.PV.management.comment')}}</el-divider>
                    <el-form-item :label="$t('new.PV.management.comment')" prop="comment">
                        <el-input type="textarea" v-model="form.comment"  :rows="3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="btn-wrap">
            <el-button type="primary" @click="saveOrUpdate('form')">{{$t('userInfo.alter')}}</el-button>
            <el-button @click="()=>{$router.push({path:'/adminModule'})}">{{$t('uerInfo.back')}}</el-button>
        </div>
    </div>
</template>

<script>
import baseFile from '../../components/common/baseFileInput'
export default {
    components: {
        baseFile
    },
    props: {

    },
    data() {
        this.test_tempModU0rel = async(rule, value, callback) => {
            if(this.form.tempModU0rel==''||this.form.tempModU0 == ''){
                callback(new Error(this.$t('remind.not-null')));
            }else{
               callback();
            }
        }
        return {
            form:{
                manufacturerID: '',
                name: '',
                pModMPP:'',
                tolerancePModMPP: '',
                uModMPP: '',
                iModMPP:'',
                uMod0: '',
                iModSC: '',
                uModMax: '',
                etaMod: '',
                isGeneratorGroundRequired: '',
                cellsCount: '',
                moduleWeight: '',
                moduleLength: '',
                moduleWidth: '',
                materialID: '',
                tempModUMPPrel: '',
                tempModIMPP: '',
                tempModU0rel: '',
                tempModU0: '',
                tempModISCrel: '',
                tempModISC: '',
                toleranceModU0: '',
                toleranceModUMPP: '',
                toleranceModIMPP: '',
                toleranceModISC: '',
                comment: ''
            },
            manufacturerList:[],
            materialList:[],
            moduleID: this.$route.query.id
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.systemTManufacturerGetList()
        this.systemTMaterialGetList()
        if(this.moduleID){
            this.systemModuleGetById()
        }
    },
    methods: {
        myFixed(num,len){
            if(typeof num =='number'){
                let s_num = num.toString()
                if(s_num.indexOf('.')==-1){
                    return num
                }else{
                    let arr_num = s_num.split('.')
                    if(arr_num[1].length>len){
                        return num.toFixed(len)
                    }else{
                        return num
                    }
                }
            }else{
                return ''
            }
        },
        //开路电压mV/°C的计算公式为：开路电压*开路电压%/°C*10
        //短路电流​mA/°C的计算公式为：短路电流*短路电流%/°C*10
        calcTempModU0(val){
            if(val=='total'){
                if(this.form.uMod0&&this.form.tempModU0){
                    this.form.tempModU0rel = this.myFixed( this.form.tempModU0/(this.form.uMod0 *10), 4 )
                }
                // this.$refs['form'].validateField('tempModIMPP')
            }else{
                if(this.form.uMod0&&this.form.tempModU0rel){
                    this.form.tempModU0 = this.myFixed( this.form.uMod0 * this.form.tempModU0rel * 10, 4)
                }
            }
        },
        calcTempModISC(val){
            if(val=='total'){
                if(this.form.tempModISC&&this.form.iModSC){
                    this.form.tempModISCrel = this.myFixed( this.form.tempModISC/(this.form.iModSC *10), 4 )
                }
            }else{
                if(this.form.iModSC&&this.form.tempModISCrel){
                    this.form.tempModISC = this.myFixed( this.form.iModSC * this.form.tempModISCrel * 10, 4 )
                }
            }
        },
        calcMPPvol(val){
            if(val=='total'){
                if(this.form.tempModIMPP&&this.form.uModMPP){
                    this.form.tempModUMPPrel = this.myFixed( this.form.tempModIMPP/(this.form.uModMPP *10), 4 )
                }
            }else{
                if(this.form.uModMPP&&this.form.tempModUMPPrel){
                    this.form.tempModIMPP = this.myFixed( this.form.uModMPP * this.form.tempModUMPPrel * 10, 4 )
                }
            }
        },
        addManufacturer() {
            this.$msgbox.prompt('', this.$t('PV.addManufacturer'), {
                confirmButtonText: this.$t('common.button.ok'),
                cancelButtonText: this.$t('common.button.close'),
            }).then(({ value }) => {
                this.tmanufacturerSave(value)
            }).catch(() => {
            });
        },
        async tmanufacturerSave(val){
            let user=JSON.parse(sessionStorage.getItem('userInfo'));
            let res=await this.API.tmanufacturerSave({
                userId:user.userId,
                name:val
            })
            if(res.success){
                this.systemTManufacturerGetList(res.object.manufacturerID)//刷新
                
            }else{
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },
        saveOrUpdate(formName){
            let $this = this
            this.$refs[formName].validate((valid) => {
              if (valid) {
                  if($this.moduleID){
                      $this.form.moduleID = $this.moduleID
                      $this.update($this.form)
                  }else {
                      $this.save($this.form)
                  }
              } else {
                return false;
              }
            });
        },

        /*更新*/
        async update(data){
            let res = await this.API.systemModuleUpdate(data)
            this.showMessage(res)

        },


        /*创建*/
        async save(data){
            let res = await this.API.systemModuleSave(data)
            this.showMessage(res)
        },


        showMessage(res){
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.$router.push({path: '/adminModule'});
            }else {
                this.$message({
                    message: res.msg,
                    type: 'warning'
                });
            }
        },



        /*获取详情数据*/
        async systemModuleGetById(){
            let res = await this.API.systemModuleGetById({
                moduleID: this.moduleID
            })
            this.form = res
        },

        /*获取供应商*/
        async systemTManufacturerGetList(id){
            let res = await this.API.systemTManufacturerGetList()
            this.manufacturerList = res
            if(id){
                this.form.manufacturerID=id
            }
        },

        /*获取晶片工艺*/
        async systemTMaterialGetList(){
            let res = await this.API.systemTMaterialGetList()
            this.materialList = res;
        }
    },
};
</script>

<style lang="less">
    .save-main-box{
        display: flex;
        justify-content: space-evenly;
    }
    .el-input-group__append, .el-input-group__prepend{
        padding: 0;
        width: 38px;
        text-align: center;
        font-size: 12px;
    }
    .el-input-group+.el-input-group{
        margin-left:10px;
    }
    .el-input-group.inline{
        width:48%;
    }
    .outIcon{
        font-size: 24px;
        color: #8a8a8a;
    }
    .el-table__body{
        width:100% !important;
    }
    input[type=number]{
        padding-right: 0;
    }
</style>
<style scoped lang="less">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
-webkit-appearance: none !important;
}
::v-deep input[type='number'] {
-moz-appearance: textfield !important;
}
::v-deep .el-form-item__error{
    word-break:keep-all;
   
}
</style>
